<template>
  <ValidationObserver
    ref="form"
    v-slot="{ passes }"
    tag="section"
    class="pt-3"
  >
    <van-cell-group
      title="基本信息"
      class="mt-2"
    >
      <ValidationProvider
        v-slot="{ errors }"
        vid="status"
        name="状态"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="form.status"
          required
          readonly
          clearable
          placeholder="请选择状态"
          autocomplete="off"
          label="状态"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showStatusPicker = true"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        vid="responsible"
        name="负责人"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          :value="form.responsible ? form.responsible.text : ''"
          required
          readonly
          clearable
          placeholder="请选择负责人"
          autocomplete="off"
          label="负责人"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showResponsiblePicker = true"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        vid="reply"
        name="答复"
        mode="lazy"
        rules="min:2|max:5000"
        slim
      >
        <van-field
          v-model="form.reply"
          clearable
          placeholder="请输入答复"
          autocomplete="off"
          label="答复"
          autosize
          rows="2"
          type="textarea"
          maxlength="5000"
          show-word-limit
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>
    </van-cell-group>

    <van-cell-group
      title="上传照片"
      class="mt-2 pt-2 px-4"
    >
      <van-uploader
        v-model="form.reply_attachments"
        multiple
        :before-read="beforeRead"
        @delete="onImageDelete"
      />
    </van-cell-group>
    <div class="mt-4 w-full flex flex-col justify-start items-center">
      <div class="px-2 w-3/4">
        <van-button
          type="danger"
          block
          round
          :loading="processing"
          loading-text="发送中..."
          @click="passes(onSubmit)"
        >
          <van-icon name="guide-o" /> 保存修改
        </van-button>
      </div>

      <div
        v-if="issue.status === '已完成' && issue.openid"
        class="px-2 w-3/4 mt-3"
      >
        <van-button
          type="default"
          block
          round
          :loading="messaging"
          loading-text="发送中..."
          @click="sendWechatNotification"
        >
          <van-icon name="chat-o" /> 发送微信消息
        </van-button>
      </div>
    </div>
    <van-popup
      v-model="showResponsiblePicker"
      round
      get-container="body"
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="responsibles"
        :default-index="responsibleDefaultIndex"
        @cancel="showResponsiblePicker = false"
        @confirm="onResponsibleConfirm"
      />
    </van-popup>
    <van-popup
      v-model="showStatusPicker"
      round
      get-container="body"
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :default-index="statusDefaultIndex"
        :columns="Object.keys(issuesStatus)"
        @cancel="showStatusPicker = false"
        @confirm="onStatusConfirm"
      />
    </van-popup>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import images from '@/mixins/uploader/images'
export default {
  name: 'WorkspaceIssueManage',
  mixins: [images],
  props: {
    issue: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      messaging: false,
      showStatusPicker: false,
      showResponsiblePicker: false,
      form: {
        status: null,
        responsible: null
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapGetters('Common/Credential', ['userInfo']),
    ...mapState('Common/Config', ['issuesStatus']),
    responsibles() {
      return (this.$store.state.Workspace.Committee.committees ?? []).map(
        item => ({
          text: item.remark ?? item.nickName ?? '未知',
          value: item.openid
        })
      )
    },
    statusDefaultIndex() {
      if (!this.form.status || !this.issuesStatus) {
        return 0
      }
      let pos = Object.keys(this.issuesStatus).indexOf(this.form.status)
      return pos >= 0 ? pos : 0
    },
    responsibleDefaultIndex() {
      if (!this.form.responsible || !this.responsibles) {
        return 0
      }

      let pos = this.responsibles.findIndex(
        item => item.value == this.form.responsible.value
      )
      return pos >= 0 ? pos : 0
    }
  },
  watch: {
    issue: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }
        let { status, responsible, reply, reply_attachments } = this.$clone(val)
        reply_attachments = (reply_attachments ?? []).map(attachment => {
          attachment.url = attachment.url + '!thumbnail'
          return attachment
        })
        this.form = { status, responsible, reply, reply_attachments }
      }
    }
  },
  mounted() {},
  methods: {
    onStatusConfirm(value) {
      this.form.status = value
      this.showStatusPicker = false
    },
    onResponsibleConfirm(value) {
      this.form.responsible = value
      this.showResponsiblePicker = false
    },
    onImageDelete(e) {
      let { fileId } = e
      if (fileId) {
        let pos = this.form.reply_attachments.findIndex(
          attachment => attachment.fileId == fileId
        )
        this.form.reply_attachments.splice(pos, 0)
      }
      // eslint-disable-next-line no-console
      console.warn('onImageDelete', e)
    },
    sendWechatNotification() {
      if (!['已完成', '处理中'].includes(this.form.status)) {
        this.$notify({
          type: 'danger',
          message: '当前任务状态为待处理，无法发送消息通知'
        })
        return
      }

      const data = {
        $url: 'onIssueFinished',
        userInfo: this.userInfo,
        id: this.$route.params.issue
      }
      // eslint-disable-next-line no-console
      console.warn('sendWechatNotification', JSON.stringify(data))
      this.$dialog
        .confirm({
          title: '发送消息通知吗？',
          message: '您确定要给业主发送消息通知吗？消息通知发送后无法撤回！',
          theme: 'round-button',
          cancelButtonText: '不发了',
          confirmButtonText: '确定发送'
        })
        .then(() => {
          this.messaging = true
          this.tcb
            .callFunction({
              name: 'wechat',
              data
            })
            .then(res => {
              // eslint-disable-next-line no-console
              console.log(res)
              let { result } = res
              if (result.code.toString().indexOf('2') == 0) {
                this.$dialog
                  .confirm({
                    title: '发送成功',
                    message: '您的消息通知已经发送！请注意不要打扰到业主哦。',
                    theme: 'round-button',
                    cancelButtonText: '留在当前页',
                    confirmButtonText: '返回列表'
                  })
                  .then(() => {
                    this.$router.go(-1)
                  })
                  .catch(() => {
                    this.$emit('input')
                  })
              } else {
                this.$notify({
                  type: 'danger',
                  message: result.error
                })
              }
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.error(e)
              this.$notify({
                type: 'danger',
                message: '发送失败，由于微信服务异常，暂时无法发送'
              })
            })
            .finally(() => {
              this.messaging = false
            })
        }).catch(() => {})
    },
    onSubmit() {
      this.processing = true
      const data = {
        $url: 'workspacePost',
        userInfo: this.userInfo,
        id: this.$route.params.issue,
        form: this.form
      }
      // eslint-disable-next-line no-console
      console.warn('onSubmit', JSON.stringify(data))
      this.tcb
        .callFunction({
          name: 'issues',
          data
        })
        .then(res => {
          // eslint-disable-next-line no-console
          console.log(res)
          let { result } = res
          if (result.code.toString().indexOf('2') == 0) {
            this.$dialog
              .confirm({
                title: '发送成功',
                message: '意见/建议的信息修改成功！',
                theme: 'round-button',
                cancelButtonText: '留在当前页',
                confirmButtonText: '返回列表'
              })
              .then(() => {
                this.$router.go(-1)
              })
              .catch(() => {
                this.$emit('input')
              })
          } else {
            this.$notify({
              type: 'danger',
              message: result.error
            })
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$notify({
            type: 'danger',
            message: '发送失败，由于微信服务异常，暂时无法发送'
          })
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
