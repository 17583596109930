var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-screen"},[_vm._l((_vm.issue.discussions),function(discussion){return _c('div',{key:("discussion-" + (discussion._id)),staticClass:"m-3"},[_c('van-cell',{staticClass:"issue-item",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(discussion.content || '无')+" ")])]},proxy:true}],null,true)}),(
        discussion.subscriber &&
          (discussion.subscriber.remark || discussion.subscriber.nickName)
      )?_c('van-cell',{staticClass:"issue-item",attrs:{"title":"发表人","value":discussion.subscriber.remark
          ? discussion.subscriber.remark
          : discussion.subscriber.nickName}}):_vm._e(),_c('van-cell',{staticClass:"issue-item",attrs:{"title":"发表时间","value":_vm.$dayjs(discussion.createdAt).format('YYYY-MM-DD HH:mm:ss')}}),(discussion.attachments && discussion.attachments.length > 0)?_c('van-cell',{staticClass:"issue-item no-uploader",attrs:{"title":"照片"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('van-uploader',{attrs:{"readonly":true,"preview-options":{images: discussion.preview_images},"multiple":""},model:{value:(discussion.attachments),callback:function ($$v) {_vm.$set(discussion, "attachments", $$v)},expression:"discussion.attachments"}})]},proxy:true}],null,true)}):_vm._e()],1)}),_c('h3',{staticClass:"ml-4 mt-8 font-bold"},[_vm._v(" 发表新的讨论内容 ")]),_c('ValidationObserver',{ref:"form",staticClass:"m-3 bg-white",attrs:{"tag":"section"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passes = ref.passes;
return [_c('ValidationProvider',{attrs:{"vid":"content","name":"内容","mode":"lazy","rules":"required|min:2|max:5000","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('van-field',{attrs:{"clearable":"","placeholder":"请输入内容","autocomplete":"off","label":"内容","autosize":"","required":"","rows":"2","type":"textarea","maxlength":"5000","show-word-limit":"","error":errors.length > 0,"error-message":errors[0]},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}],null,true)}),_c('van-cell',{staticClass:"issue-item",attrs:{"title":"上传照片"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('van-uploader',{attrs:{"multiple":"","before-read":_vm.beforeRead},model:{value:(_vm.form.attachments),callback:function ($$v) {_vm.$set(_vm.form, "attachments", $$v)},expression:"form.attachments"}})]},proxy:true}],null,true)}),_c('div',{staticClass:"py-4 px-8 w-full"},[_c('van-button',{attrs:{"type":"danger","block":"","round":"","loading":_vm.processing,"loading-text":"发送中..."},on:{"click":function($event){return passes(_vm.onSubmit)}}},[_vm._v(" 发送 ")])],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }