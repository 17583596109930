<template>
  <section class="min-h-screen">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <van-tabs
        v-model="activeTabName"
        sticky
        animated
        class="min-h-screen"
      >
        <van-tab
          title="意见建议"
          name="basic"
          class="bg-gray-100 tab-content-container pt-2"
        >
          <Basic
            :issue="issue"
            module="workspace"
          />
        </van-tab>
        <van-tab
          title="工作讨论"
          name="discussion"
          class="bg-gray-100 tab-content-container"
          @input="fetch"
        >
          <Discussion
            :issue="issue"
            @input="fetch"
          />
        </van-tab>
        <van-tab
          title="管理操作"
          name="manage"
          class="bg-gray-100 tab-content-container"
        >
          <Manage
            :issue="issue"
            @input="fetch"
          />
        </van-tab>
      </van-tabs>

      <van-empty
        v-if="!issue"
        description="无法加载意见/建议信息，请稍后再试！"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Basic from '@/components/issue/basic'
import Manage from './components/manage'
import Discussion from './components/discussion'
import share from '@/mixins/share'
export default {
  name: 'WorkspaceIssueEdit',
  components: {
    Basic,
    Manage,
    Discussion
  },
  mixins: [share],
  data() {
    return {
      loading: true,
      processing: false,
      issue: null,
      activeTabName: 'basic'
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  async mounted() {
    await Promise.all([
      this.fetch(),
      this.$store.dispatch('Workspace/Committee/fetch'),
      this.prepareWx()
    ])
    let options = {
      title: this.issue?.title,
      desc: this.issue?.content,
    }
    if(this.issue.attachments && this.issue.attachments.length > 0) {
      options.imgUrl = this.issue.attachments[0]
    }
    this.updateShareData(options)
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'issues',
          data: {
            $url: 'workspaceGet',
            id: this.$route.params.issue
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关意见/建议', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let issue = response.result.data
          let discussions = Array.isArray(issue.discussions)
            ? issue.discussions
            : []
          discussions = discussions.map(discussion => {
            if (!Array.isArray(discussion.attachments)) {
              return discussion
            }
            discussion.preview_images = discussion.attachments.map(
              attachment => {
                return attachment.url + '!fullscreen'
              }
            )
            discussion.attachments = discussion.attachments.map(attachment => {
              let { url, ...extras } = attachment
              url += '!thumbnail'
              return Object.assign(extras, { url })
            })
            return discussion
          })
          issue.discussions = discussions
          this.$set(this, 'issue', issue)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取意见和建议', err)
        this.issue = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
.tab-content-container {
  min-height: calc(100vh - 44px);
}
</style>
