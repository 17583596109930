<template>
  <div class="w-screen">
    <div
      v-for="discussion of issue.discussions"
      :key="`discussion-${discussion._id}`"
      class="m-3"
    >
      <van-cell class="issue-item">
        <template #title>
          <p class="font-medium">
            {{ discussion.content || '无' }}
          </p>
        </template>
      </van-cell>
      <van-cell
        v-if="
          discussion.subscriber &&
            (discussion.subscriber.remark || discussion.subscriber.nickName)
        "
        title="发表人"
        :value="
          discussion.subscriber.remark
            ? discussion.subscriber.remark
            : discussion.subscriber.nickName
        "
        class="issue-item"
      />
      <van-cell
        title="发表时间"
        :value="$dayjs(discussion.createdAt).format('YYYY-MM-DD HH:mm:ss')"
        class="issue-item"
      />

      <van-cell
        v-if="discussion.attachments && discussion.attachments.length > 0"
        title="照片"
        class="issue-item no-uploader"
      >
        <template #label>
          <van-uploader
            v-model="discussion.attachments"
            :readonly="true"
            :preview-options="{images: discussion.preview_images}"
            multiple
          />
        </template>
      </van-cell>
    </div>

    <h3 class="ml-4 mt-8 font-bold">
      发表新的讨论内容
    </h3>
    <ValidationObserver
      ref="form"
      v-slot="{ passes }"
      tag="section"
      class="m-3 bg-white"
    >
      <ValidationProvider
        v-slot="{ errors }"
        vid="content"
        name="内容"
        mode="lazy"
        rules="required|min:2|max:5000"
        slim
      >
        <van-field
          v-model="form.content"
          clearable
          placeholder="请输入内容"
          autocomplete="off"
          label="内容"
          autosize
          required
          rows="2"
          type="textarea"
          maxlength="5000"
          show-word-limit
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>
      <van-cell
        title="上传照片"
        class="issue-item"
      >
        <template #label>
          <van-uploader
            v-model="form.attachments"
            multiple
            :before-read="beforeRead"
          />
        </template>
      </van-cell>
      <div class="py-4 px-8 w-full">
        <van-button
          type="danger"
          block
          round
          :loading="processing"
          loading-text="发送中..."
          @click="passes(onSubmit)"
        >
          发送
        </van-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import images from '@/mixins/uploader/images'
export default {
  name: 'WorkspaceIssueDiscussion',
  mixins: [images],
  props: {
    issue: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      form: {
        content: null,
        attachments: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapGetters('Common/Credential', ['userInfo'])
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.processing = true
      const data = {
        $url: 'workspacePostDiscussion',
        userInfo: this.userInfo,
        id: this.$route.params.issue,
        form: this.form
      }
      // eslint-disable-next-line no-console
      console.warn('onSubmit', JSON.stringify(data))
      this.tcb
        .callFunction({
          name: 'issues',
          data
        })
        .then(() => {
          this.$toast('内容发送成功')
          this.$emit('input')
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$notify({
            type: 'danger',
            message: '发送失败，由于微信服务异常，暂时无法发送'
          })
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
